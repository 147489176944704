import { useState } from 'react';
import Carousel from 'react-multi-carousel';
import { useRouter } from 'next/router';
import Link from 'next/link';
// import dynamic from 'next/dynamic';
import 'react-multi-carousel/lib/styles.css';
import cx from 'classnames';
import styles from './FourUpSlider.module.sass';
import clearHtml from '@/utils/clearHtmlElements';
import noLinkHash from '@/utils/noLinkHash';
import { useGetPostsByCategoryQuery } from '../../../generated/graphql';
import { responsive, imageOnlyResponsive } from './responsive';
import Image from 'next/image';
import Background from '@/components/Background';
import Container from '@/components/Container';
import SpacingLayout from '@/components/SpacingLayout';
import SubHeader from '@/components/SubHeader';

const FourUpSlider = props => {
    const { subBackground, subLayout, subHeader, subPost, sliderOptions } = props;
    const { sliderSlideHeight, slideTextAlignment, hideNumbers, imageOnlyVersion, imageGallery } = sliderOptions;
    const [categoryPostData, setCategoryPostData] = useState([]);

    const { postToShow, selectedPosts, customContentFields, categoryToShow } = subPost;

    let categoryNameData = postToShow === 'By Category' ? categoryToShow?.map(category => category.name).join(', ') : '';
    const router = useRouter();
    const path = router.asPath;

    // if (postToShow === 'By Category') {
    const { data, loading, error } = useGetPostsByCategoryQuery({
        variables: {
            categoryName: categoryNameData || 'uncategorized'
        }
    });
    if (loading) return <p>Loading...</p>;
    if (error) return <p>An error has occurred</p>;
    if (categoryPostData.length === 0 && postToShow === 'By Category') {
        setCategoryPostData(data);
    }
    // }

    // const shouldShowSelected = postToShow === 'Selected';
    const customContent = postToShow === 'Custom Content (For Four-Up Slider)';

    const posts = categoryPostData?.posts?.nodes || selectedPosts;

    const selectedList = posts?.map(entry => {
        switch (entry?.__typename) {
            case 'Post':
                return {
                    title: entry?.title,
                    uri: `${entry?.uri}`,
                    excerpt: entry?.excerpt,
                    image: entry?.featuredImage?.node
                };
            case 'Page':
                return {
                    title: entry?.title,
                    uri: `${entry?.uri}`,
                    excerpt: entry?.excerpt,
                    image: entry?.featuredImage?.node
                };
            default:
                return {};
        }
    });

    let sliderContent = customContent ? customContentFields : selectedList;
    if (imageOnlyVersion) sliderContent = null;

    const linkAlignment = slideTextAlignment === 'center' ? 'left-[100px]' : slideTextAlignment === 'right' ? 'right-[13px]' : '';

    const widthClasses = imageOnlyVersion ? 'w-[185px] sm:w-[370px] md:w-[555px] lg:w-auto lg:max-w-[740px]' : 'w-[325px] md:w-[650px] xl:w-[976px] 4xl:w-auto 4xl:max-w-[1300px]';

    const ButtonGroup = ({ next, previous }) => {
        return (
            <div
                className={`${styles.fourUpSliderButtonGroup} pointer-events-none absolute top-0 hidden h-full vsm:right-[-26px] vsm:flex vsm:flex-row vsm:justify-between xsm:right-[-55px] xl:right-[-53px] 2xl:right-[-55px]`}>
                <button className={`${styles.arrowLeft} pointer-events-auto border-0 opacity-100 xsm:ml-5`} onClick={() => previous()} />
                <button className={`${styles.arrowRight} pointer-events-auto border-0 opacity-100 xsm:mr-5`} onClick={() => next()} />
            </div>
        );
    };

    const CarouselComponent = () => (
        <Background {...subBackground}>
            <Container width="noClasses" className={`${styles.fourUpSliderContainer} relative ${widthClasses}`}>
                <SpacingLayout defaultSpacing={subLayout?.defaultSpacing} className={subLayout?.layoutCssSafelist} fourUpSlider>
                    <SubHeader {...subHeader} />
                    {(sliderContent || imageOnlyVersion) && (
                        <Carousel
                            swipeable={true}
                            draggable={true}
                            showDots={false}
                            infinite={true}
                            shouldResetAutoplay={false}
                            ssr
                            deviceType="desktop"
                            keyBoardControl={true}
                            containerClass={`carouselContainer mx-auto ${widthClasses}`}
                            arrows={false}
                            sliderClass="list-none"
                            customButtonGroup={<ButtonGroup />}
                            renderButtonGroupOutside={true}
                            customTransition={'transform 800ms ease-in-out'}
                            transitionDuration={800}
                            centerMode={false}
                            partialVisible={false}
                            itemClass={cx(`${styles.slideContainer} ${imageOnlyVersion ? styles.imageOnly : ''}`, !imageOnlyVersion && 'dark:ring-0 dark:outline dark:outline-1')}
                            slidesToSlide={4}
                            responsive={imageOnlyVersion ? imageOnlyResponsive : responsive}>
                            {imageOnlyVersion &&
                                imageGallery?.map((img, index) => {
                                    const { caption, sourceUrl, altText } = img;
                                    return <Image key={index} alt={altText} src={sourceUrl} height={175} width={175} className="mx-auto" />;
                                })}
                            {sliderContent?.map((slide, index) => {
                                const title = customContent ? slide?.slideTitle : slide?.title;
                                const uri = customContent ? slide?.slideLink?.url || null : slide?.uri;
                                const excerpt = customContent ? slide?.slideContent : slide?.excerpt;
                                const subtitle = slide?.slideSubtitle || null;
                                const linkText = slide?.slideLink?.title || 'Read now ›';
                                const image = customContent ? slide?.slideImage : slide?.image;

                                let href = uri || null;
                                let slideTitle = clearHtml(title).split(' ');
                                let titleSubstring = slideTitle
                                    .filter(word => {
                                        if (slideTitle.indexOf(word) <= 9) {
                                            return word;
                                        }
                                    })
                                    .join(' ');
                                let finalTitle = slideTitle?.length <= 10 ? titleSubstring : `${titleSubstring}...`;

                                let postExcerpt, cleanExcerpt;
                                if (excerpt) {
                                    postExcerpt = clearHtml(excerpt);
                                    cleanExcerpt = postExcerpt?.length <= 150 ? postExcerpt : `${postExcerpt.substring(0, 150)}...`;
                                }
                                let desc = cleanExcerpt || '';
                                let grayedOut = href === path ? 'opacity-30 pointer-events-none' : '';
                                return (
                                    <div key={index + 1} className={cx([`${grayedOut} text-${slideTextAlignment} relative mr-8 w-full`, hideNumbers || 'px-4'])}>
                                        {!hideNumbers && <h1 className="mt-4 text-gray">{index + 1}</h1>}
                                        {image && sliderSlideHeight === '465px' && (
                                            <Image
                                                alt={image?.altText}
                                                src={image?.sourceUrl}
                                                height={imageOnlyVersion ? 175 : 169}
                                                width={imageOnlyVersion ? 175 : 300}
                                                className="mx-auto"
                                            />
                                        )}
                                        <div className="fourUpSliderContentContainer mx-4">
                                            {href ? (
                                                <h4 className={cx(['mb-2 mt-4', !subtitle && 'mb-2'])}>
                                                    <Link className="font-semibold" href={href} scroll={noLinkHash(href)} dangerouslySetInnerHTML={{ __html: finalTitle }} />
                                                </h4>
                                            ) : (
                                                <h4 className={cx(['mb-2 mt-4', !subtitle && 'mb-4'])} dangerouslySetInnerHTML={{ __html: finalTitle }} />
                                            )}
                                            {subtitle && <p className="mb-4" dangerouslySetInnerHTML={{ __html: subtitle }} />}
                                            <p dangerouslySetInnerHTML={{ __html: desc }} />
                                            <p className={cx(['mb-7 mt-3', linkAlignment])}>
                                                {href && (
                                                    <Link href={href} scroll={noLinkHash(href)}>
                                                        {linkText}
                                                    </Link>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })}
                        </Carousel>
                    )}
                </SpacingLayout>
            </Container>
        </Background>
    );
    return <CarouselComponent />;
};

export default FourUpSlider;
