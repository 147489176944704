export const responsive = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1300
        },
        items: 4
        // partialVisibilityGutter: 100,
    },
    laptop: {
        breakpoint: {
            max: 1299,
            min: 1099
        },
        items: 3,
        slidesToSlide: 3
        //   partialVisibilityGutter: 0,
    },
    tablet: {
        breakpoint: {
            max: 1098,
            min: 767
        },
        items: 2,
        slidesToSlide: 2
    },

    mobile: {
        breakpoint: {
            max: 766,
            min: 0
        },
        items: 1,
        slidesToSlide: 1
    }
};

export const imageOnlyResponsive = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 992
        },
        items: 4,
        slidesToSlide: 4
        // partialVisibilityGutter: 100,
    },
    tablet: {
        breakpoint: {
            max: 991,
            min: 767
        },
        items: 3,
        slidesToSlide: 3
        //   partialVisibilityGutter: 0,
    },
    smallTablet: {
        breakpoint: {
            max: 766,
            min: 640
        },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: {
            max: 639,
            min: 0
        },
        items: 1,
        slidesToSlide: 1
    }
};
